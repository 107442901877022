import { useContext } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { ThermometerHigh } from "react-bootstrap-icons"

import { ThemeContext } from "infra/component/theme/ThemeContext"

interface PageHeadSectionProps {
  img: React.ReactNode
  imgClassNames?: string
  heading: string
  children?: React.ReactNode
}

export const PageHeadSection = ({
  img,
  imgClassNames,
  heading,
  children,
}: PageHeadSectionProps) => {
  const { theme } = useContext(ThemeContext)

  return (
    <Row as="section" className={`mb-5 bd-top-section-${theme}`}>
      <Col>
        <Container className="mt-4 bd-masthead">
          <Row className="align-items-lg-center">
            <Col
              xs="8"
              md="4"
              lg="5"
              className="mx-auto order-md-2 text-center mb-3"
            >
              <div className={`d-inline-block ${imgClassNames}`}>{img}</div>
            </Col>
            <Col md="8" lg="7" className="order-md-1 text-center text-md-start">
              <h1 className="mb-3">{heading}</h1>
              <p className={`lead lead-${ThermometerHigh}`}>{children}</p>
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  )
}

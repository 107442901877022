import { createContext, useState } from "react"

export enum Theme {
  LIGHT = "light",
  DARK = "dark",
}

interface ThemeContextInterface {
  theme: Theme
  changeTheme: () => void
}

export const ThemeContext = createContext<ThemeContextInterface>(
  {} as ThemeContextInterface
)

interface ThemeContextProviderProps {
  children: React.ReactNode
}

const getThemeFromSystem = () => {
  const isLight = window.matchMedia?.("(prefers-color-scheme: light)").matches
  return isLight ? Theme.LIGHT : Theme.DARK
}

export const ThemeContextProvider = ({
  children,
}: ThemeContextProviderProps) => {
  const [theme, setTheme] = useState(getThemeFromSystem())

  const changeTheme = () =>
    setTheme((prev) => (prev === Theme.LIGHT ? Theme.DARK : Theme.LIGHT))

  return (
    <ThemeContext.Provider value={{ theme, changeTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

import { Col, Nav, NavDropdown, Navbar, Row } from "react-bootstrap"
import { Github, Twitter } from "react-bootstrap-icons"
import { NavLink } from "react-router-dom"

import { ContactButton } from "infra/component/contact/ContactButton"
import { ThemeButton } from "infra/component/theme/ThemeButton"
import { ReactComponent as LogoSVG } from "infra/media/fluxibility-logo.svg"

export const Header = () => (
  <header className="fixed-top">
    <Row className="border-bottom border-light">
      <Col className="navbar-dark bd-navbar bg-primary">
        <Navbar variant="dark" expand="md" className="container-xxl">
          <Navbar.Brand as={NavLink} to="/" href="/" className="p-2">
            <LogoSVG
              style={{
                fill: "#fff",
                border: "1px solid",
                borderRadius: 5,
                padding: "4px 6px 6px 4px",
              }}
              width="35"
              height="35"
            />
          </Navbar.Brand>
          <Navbar.Toggle className="me-1" />
          <Navbar.Collapse>
            <Nav className="flex-row flex-wrap">
              <Nav.Link as={NavLink} className="p-2" to="/">
                Mission
              </Nav.Link>
              <Nav.Link as={NavLink} className="p-2" to="/services">
                Services
              </Nav.Link>
              <Nav.Link as={NavLink} className="p-2" to="/products">
                Products
              </Nav.Link>
              <NavDropdown.Divider className="col-12 d-md-none border-color-style" />
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse className="justify-content-end">
            <Nav>
              <Nav.Link
                className="col-6 col-md-auto navbar-svg p-2"
                href="https://github.com/fluxibility"
                target="_blank"
              >
                <Github color="white" width="30" height="30" />
                <small className="d-md-none ms-2">GitHub</small>
              </Nav.Link>
              <Nav.Link
                className="col-6 col-md-auto navbar-svg p-2"
                href="https://twitter.com/fluxibilityio"
                target="_blank"
              >
                <Twitter color="white" width="30" height="30" />
                <small className="d-md-none ms-2">Twitter</small>
              </Nav.Link>
              <Nav.Item className="col-6 col-md-auto p-2">
                <ContactButton />
              </Nav.Item>
              <Nav.Item className="col-6 col-md-auto p-2">
                <ThemeButton />
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Col>
    </Row>
  </header>
)

import { SVGProps, useContext } from "react"

import { ThemeContext } from "infra/component/theme/ThemeContext"

export const FluxibilityProductsSVG = (props: SVGProps<any>) => {
  const { theme } = useContext(ThemeContext)

  return (
    <svg
      {...props}
      version="1.1"
      viewBox="0 0 238.12 211.66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="paint1_linear" gradientUnits="userSpaceOnUse">
          <stop stopColor="#596eb0" offset="0" />
          <stop stopColor="#495ea0" offset=".995" />
        </linearGradient>
      </defs>
      <g transform="matrix(2.9282 0 0 2.8911 118.85 153.55)" fill="#adb5bd">
        <path
          d="m6 3.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5v1h5.5a0.5 0.5 0 0 1 0.5 0.5v1a0.5 0.5 0 0 1-1 0v-0.5h-5v0.5a0.5 0.5 0 0 1-1 0v-0.5h-5v0.5a0.5 0.5 0 0 1-1 0v-1a0.5 0.5 0 0 1 0.5-0.5h5.5v-1a1.5 1.5 0 0 1-1.5-1.5zm2.5 1.5a0.5 0.5 0 0 0 0.5-0.5v-1a0.5 0.5 0 0 0-0.5-0.5h-1a0.5 0.5 0 0 0-0.5 0.5v1a0.5 0.5 0 0 0 0.5 0.5zm-8.5 6.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5zm1.5-0.5a0.5 0.5 0 0 0-0.5 0.5v1a0.5 0.5 0 0 0 0.5 0.5h1a0.5 0.5 0 0 0 0.5-0.5v-1a0.5 0.5 0 0 0-0.5-0.5zm4.5 0.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5zm1.5-0.5a0.5 0.5 0 0 0-0.5 0.5v1a0.5 0.5 0 0 0 0.5 0.5h1a0.5 0.5 0 0 0 0.5-0.5v-1a0.5 0.5 0 0 0-0.5-0.5zm4.5 0.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5zm1.5-0.5a0.5 0.5 0 0 0-0.5 0.5v1a0.5 0.5 0 0 0 0.5 0.5h1a0.5 0.5 0 0 0 0.5-0.5v-1a0.5 0.5 0 0 0-0.5-0.5z"
          fillRule="evenodd"
        />
      </g>
      <g transform="matrix(2.9445 0 0 2.9445 24.899 24.004)" fill="#007bff">
        <path d="m6 9a0.5 0.5 0 0 1 0.5-0.5h3a0.5 0.5 0 0 1 0 1h-3a0.5 0.5 0 0 1-0.5-0.5zm-2.146-4.854a0.50063 0.50063 0 1 0-0.708 0.708l1.647 1.646-1.647 1.646a0.50063 0.50063 0 1 0 0.708 0.708l2-2a0.5 0.5 0 0 0 0-0.708z" />
        <path d="m2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-10a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-12a1 1 0 0 1-1-1v-10a1 1 0 0 1 1-1z" />
      </g>
      <g transform="matrix(2.6661 0 0 2.6661 162.24 7.6124)" fill="#20c997">
        <path d="m11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h0.5a0.5 0.5 0 0 1 0 1h-15a0.5 0.5 0 0 1 0-1h0.5v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1v-7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1zm1 12h2v-12h-2zm-3 0v-7h-2v7zm-5 0v-3h-2v3z" />
      </g>
      <g transform="matrix(2.2502 0 0 2.2502 185.11 131.47)" fill="#e83e8c">
        <path d="m2.5 4a0.5 0.5 0 1 0 0-1 0.5 0.5 0 0 0 0 1zm2-0.5a0.5 0.5 0 1 1-1 0 0.5 0.5 0 0 1 1 0zm1 0.5a0.5 0.5 0 1 0 0-1 0.5 0.5 0 0 0 0 1z" />
        <path d="m2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-10a2 2 0 0 0-2-2zm13 2v2h-14v-2a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-13 11a1 1 0 0 1-1-1v-7h14v7a1 1 0 0 1-1 1z" />
      </g>
      <g transform="matrix(2.2518 0 0 2.2518 33.73 146.23)" fill="#dc3545">
        <path d="m-0.66388-2.5967a1.5 1.5 0 0 0-1.5 1.5v2a1.5 1.5 0 0 0 1.5 1.5h13a1.5 1.5 0 0 0 1.5-1.5v-2a1.5 1.5 0 0 0-1.5-1.5zm1 2h3a0.5 0.5 0 0 1 0 1h-3a0.5 0.5 0 0 1 0-1zm9.927 0.427a0.25 0.25 0 0 1 0.177-0.427h0.792a0.25 0.25 0 0 1 0.177 0.427l-0.396 0.396a0.25 0.25 0 0 1-0.354 0zm-12.427 5.573a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2zm1 3v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2zm14-1v-2a1 1 0 0 0-1-1h-12a1 1 0 0 0-1 1v2zm-13-1.5a0.5 0.5 0 0 1 0.5-0.5h9a0.5 0.5 0 0 1 0 1h-9a0.5 0.5 0 0 1-0.5-0.5zm0 4a0.5 0.5 0 0 1 0.5-0.5h6a0.5 0.5 0 0 1 0 1h-6a0.5 0.5 0 0 1-0.5-0.5z" />
      </g>
      <g transform="matrix(1.9235 0 0 1.9235 97.172 6.4395)" fill="#ffc107">
        <path d="m1.333 2.667c0-1.473 2.985-2.667 6.667-2.667s6.667 1.194 6.667 2.667v1.333c0 1.473-2.985 2.667-6.667 2.667s-6.667-1.194-6.667-2.667z" />
        <path d="m1.333 6.334v3c0 1.471 2.985 2.666 6.667 2.666s6.667-1.194 6.667-2.667v-2.999a6.51 6.51 0 0 1-1.458 0.79c-1.399 0.56-3.242 0.876-5.209 0.876-1.966 0-3.809-0.317-5.208-0.876a6.508 6.508 0 0 1-1.458-0.79z" />
        <path d="m14.667 11.668a6.51 6.51 0 0 1-1.458 0.789c-1.4 0.56-3.242 0.876-5.21 0.876-1.966 0-3.809-0.316-5.208-0.876a6.51 6.51 0 0 1-1.458-0.79v1.666c0 1.473 2.985 2.667 6.667 2.667s6.667-1.194 6.667-2.667z" />
      </g>
      <g transform="matrix(2.6109 0 0 2.6109 176.88 64.857)" fill="#adb5bd">
        <path d="m14.12 10.163 1.715 0.858c0.22 0.11 0.22 0.424 0 0.534l-7.568 3.785a0.598 0.598 0 0 1-0.534 0l-7.568-3.785a0.299 0.299 0 0 1 0-0.534l1.716-0.858 5.317 2.659c0.505 0.252 1.1 0.252 1.604 0l5.317-2.66zm-6.387-10.1a0.598 0.598 0 0 1 0.534 0l7.568 3.784a0.3 0.3 0 0 1 0 0.535l-7.568 3.783a0.598 0.598 0 0 1-0.534 0l-7.568-3.783a0.299 0.299 0 0 1 0-0.535z" />
        <path d="m14.12 6.576 1.715 0.858c0.22 0.11 0.22 0.424 0 0.534l-7.568 3.784a0.598 0.598 0 0 1-0.534 0l-7.568-3.784a0.299 0.299 0 0 1 0-0.534l1.716-0.858 5.317 2.659c0.505 0.252 1.1 0.252 1.604 0l5.317-2.659z" />
      </g>
      <g transform="matrix(2.5104 0 0 2.5104 15.215 84.144)" fill="#adb5bd">
        <path d="m4.5 5a0.5 0.5 0 1 0 0-1 0.5 0.5 0 0 0 0 1zm-1.5-0.5a0.5 0.5 0 1 1-1 0 0.5 0.5 0 0 1 1 0z" />
        <path d="m0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-5.5v3a1.5 1.5 0 0 1 1.5 1.5h5.5a0.5 0.5 0 0 1 0 1h-5.5a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5h-5.5a0.5 0.5 0 0 1 0-1h5.5a1.5 1.5 0 0 1 1.5-1.5v-3h-5.5a2 2 0 0 1-2-2zm1 0v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1h-12a1 1 0 0 0-1 1zm6 7.5v1a0.5 0.5 0 0 0 0.5 0.5h1a0.5 0.5 0 0 0 0.5-0.5v-1a0.5 0.5 0 0 0-0.5-0.5h-1a0.5 0.5 0 0 0-0.5 0.5z" />
      </g>
      <rect
        className={`stroke-${theme}`}
        transform="rotate(-15)"
        x="19.45"
        y="64.168"
        width="131.58"
        height="131.58"
        ry="8.4549"
        fill="url(#paint1_linear)"
        strokeWidth="2.1878"
      />
      <g
        transform="matrix(.40391 -.10823 .10823 .40391 45.766 63.831)"
        fill="#fff"
        shapeRendering="auto"
      >
        <path
          d="m138.09 134.78c-2.3544 0-3.5319 1.1774-3.5319 3.532v10.595c0 2.3545 1.1775 3.532 3.5319 3.532l36.68 0.0805h9e-3c5.9154 0 8.9914 1.945 11.334 4.7091 2.3406 2.762 3.6036 6.8006 3.6054 10.073v9e-3l0.0126 15.582-8.3453-0.0266c-1.9438 0-1.9447-7e-4 -0.97277 1.943l17.246 32.738c0.97185 2.035 0.97204 2.0338 1.8833 0.0597l17.305-32.798c0.97186-1.9441 0.98491-1.8947-0.95881-1.8947-0.36218 0-4.6112 0-8.4971-0.0223h-3e-3l-0.0125-15.504v-0.0782c0-7.3214-2.3746-15.094-7.7951-21.491-5.4174-6.393-14.227-10.955-24.785-10.959h-0.0179z"
          color="#000000"
          colorRendering="auto"
          dominantBaseline="auto"
          imageRendering="auto"
          stopColor="#000000"
          strokeWidth=".9122"
        />
        <path
          d="m225.3 5.1425-19.749 36.41c-1.1686 2.3371-1.1691 2.3364 1.1682 2.3364h9.9632v18.79c0 3.5188-1.36 7.8595-3.8764 10.829-2.5182 2.9718-5.8243 5.1059-12.184 5.1059h-58.708c-7.8711 0-16.23 2.594-23.107 8.4215-6.8729 5.8242-11.776 15.294-11.782 26.646v0.021l-0.20624 22.298 0.1275 22.411 0.25488 61.328v6e-3c0 6.3597-2.0917 9.6653-5.0635 12.184-2.9694 2.5163-3.9298 3.8764-10.829 3.8764h-22.197v-9.4207c0-2.3372-3.03e-4 -2.3387-2.3374-1.17l-36.484 19.13c-2.3736 1.0956-2.3734 1.0942 0.07322 2.2628l36.413 19.61c2.337 1.1686 2.3354 1.169 2.3354-1.1682v-10.258h22.188c7.8711 0 16.23-2.5537 23.107-8.3812 6.873-5.8242 11.782-15.294 11.782-26.646v-0.0209l0.0811-63.264-0.24421-20.479-0.0106-22.294v-6e-3c0-6.3597 2.0917-9.6653 5.0635-12.184 2.9694-2.5163 7.31-3.8742 10.829-3.8764h0.0106l2.0382-0.0383c0.0208 1.8e-4 0.0367 0 0.0569 0h56.626c11.351 0 20.822-4.9496 26.646-11.822 5.8275-6.877 8.3482-15.081 8.3813-23.108v-18.782h9.1644c2.3371 0 2.3387 6.94e-4 1.1702-2.3364l-18.437-36.484c-1.5733-2.696-0.74515-2.696-2.2626 0.07404z"
          color="#000000"
          colorRendering="auto"
          dominantBaseline="auto"
          imageRendering="auto"
          stopColor="#000000"
          strokeWidth=".90254"
        />
      </g>
      <g
        transform="matrix(2.6817 -.81093 .81093 2.6817 40.844 67.976)"
        fill="#fff"
      >
        <path d="m11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1v-12a1 1 0 0 1 1-1zm-6-1a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-12a2 2 0 0 0-2-2z" />
        <path d="m8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
      </g>
    </svg>
  )
}

import { Accordion, Col, Container, ListGroup, Row } from "react-bootstrap"
import { Helmet } from "react-helmet-async"

import { FluxibilityProductsSVG } from "domain/products/FluxibilityProductsSVG"
import ChannelPNG from "domain/products/fxschat/channel.png"
import ChatPNG from "domain/products/fxschat/chat.png"
import LoginPNG from "domain/products/fxschat/login.png"
import { ReactComponent as FXSChat } from "domain/products/fxschat/logo.svg"
import SettingsPNG from "domain/products/fxschat/settings.png"
import { PageMeta } from "infra/component/head/HeadMeta"
import { PageHeadSection } from "infra/component/page/PageHeadSection"
import SDProvider from "infra/media/SDProvider.json"

export const Products = () => (
  <>
    <PageMeta title="Products" description="Products offered (e.g. apps)" />

    <PageHeadSection
      img={
        <FluxibilityProductsSVG
          width="600"
          height="533"
          className="img-fluid mb-3 mb-md-0"
        />
      }
      heading="Mobile Apps"
    >
      Android and iOS apps built with React Native.
    </PageHeadSection>

    <Container>
      <Row as="section" className="mb-5">
        <Helmet>
          <script
            key="fluxility-products-fxschat-structured-data"
            type="application/ld+json"
          >
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "MobileApplication",
              applicationCategory: "CommunicationApplication",
              operatingSystem: "ANDROID",
              provider: SDProvider,
              offers: {
                "@type": "Offer",
                price: "0",
                priceCurrency: "USD",
              },
            })}
          </script>
        </Helmet>
        <Col md="6">
          <div className="masthead-followup-icon d-inline-block mb-2 text-white bg-primary">
            <FXSChat
              width="32"
              height="32"
              className="d-inline-block align-top"
            />
          </div>
          <h2 className="mb-1">FXSChat</h2>
          <h3 className="mb-3">fluxibility Stream Chat</h3>
          <p className="lead mb-4">
            A simple Android app to use live-streaming chats with notifications.
            This app was created because the official Twitch app doesn't have
            any options to enable notifications for chat messages. It is mainly
            focused on small streamers which don't have a lot of viewers but
            don't want to miss any interactions to grow their channel.
          </p>
          <p className="lead mb-4">Supported Services:</p>
          <ListGroup className="d-inline-block mb-5">
            <ListGroup.Item>Twitch</ListGroup.Item>
          </ListGroup>
          <Accordion className="mb-5">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Privacy Policy</Accordion.Header>
              <Accordion.Body>
              <p className="lead">Information Collected</p>
              <p>Privacy is important to us. Therefore we do not collect any of your information. All data entered into the app is only stored locally on your device and NEVER transmitted to us.</p>
              <p className="lead">Information Used</p>
              <p>No information is used because it's not collected in the first place.</p>
              <p className="lead">Information Shared</p>
              <p>User ID and messages are shared with Twitch to allow sending and receiving chat messages and provide the functionality described above.</p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <p className="lead mb-2">Status: Early Access</p>
          <div className="mb-5 justify-content-center d-flex">
            {/* Legal: Google Play and the Google Play logo are trademarks of Google LLC. */}
            <a
              href="https://play.google.com/store/apps/details?id=io.fluxibility.stream_chat_app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="img-fluid"
                style={{ width: 200 }}
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              />
            </a>
          </div>
        </Col>

        <Col md="6">
          <Container>
            <Row>
              <Col md="6">
                <img
                  className="img-fluid rounded mx-auto d-block mb-3"
                  style={{ width: 200 }}
                  src={LoginPNG}
                  alt="Screenshot login"
                />
              </Col>
              <Col md="6">
                <img
                  className="img-fluid rounded mx-auto d-block  mb-3"
                  style={{ width: 200 }}
                  src={ChannelPNG}
                  alt="Screenshot channel select"
                />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col md="6">
                <img
                  className="img-fluid rounded mx-auto d-block  mb-3"
                  style={{ width: 200 }}
                  src={ChatPNG}
                  alt="Screenshot chat"
                />
              </Col>
              <Col md="6">
                <img
                  className="img-fluid rounded mx-auto d-block mb-3"
                  style={{ width: 200 }}
                  src={SettingsPNG}
                  alt="Screenshot settings"
                />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  </>
)

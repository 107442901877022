import { Helmet } from "react-helmet-async"

import { ContactButton } from "infra/component/contact/ContactButton"
import { PageHeadSection } from "infra/component/page/PageHeadSection"
import { MAIL } from "infra/consts"
import { FluxibilityMissionSVG } from "infra/media/FluxibilitySVG"

export const Mission = () => (
  <>
    <Helmet>
      <script key="fluxility-org-structured-data" type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          name: "fluxibility",
          legalName: "fluxibility",
          url: "https://www.fluxibility.io",
          logo: "https://www.fluxibility.io/static/logo512-bg.png",
          email: MAIL,
        })}
      </script>
    </Helmet>

    <PageHeadSection
      img={
        <FluxibilityMissionSVG
          width="600"
          height="533"
          className="img-fluid mb-3 mb-md-0"
        />
      }
      heading="Flexible IT solutions and services in an ever-changing business environment"
    >
      <>
        fluxibility.io offers IT consulting and software development tailored to
        your needs on a case by case basis. Get in <ContactButton /> today!
      </>
    </PageHeadSection>
  </>
)

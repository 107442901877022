import { useContext } from "react"
import { Button } from "react-bootstrap"
import { MoonFill, Sun } from "react-bootstrap-icons"

import { Theme, ThemeContext } from "infra/component/theme/ThemeContext"

export const ThemeButton = () => {
  const { theme, changeTheme } = useContext(ThemeContext)

  const renderSymbol = () => {
    switch (theme) {
      case Theme.LIGHT:
        return <MoonFill width={30} height={30} />
      case Theme.DARK:
        return <Sun width={30} height={30} />
      default:
        return null
    }
  }

  return (
    <Button className="shadow" variant="light" onClick={changeTheme}>
      {renderSymbol()}
    </Button>
  )
}

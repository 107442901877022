import { useContext } from "react"

import { ThemeContext } from "infra/component/theme/ThemeContext"

export const Footer = () => {
  const { theme } = useContext(ThemeContext)
  return (
    <footer
      className={`row footer mt-auto py-3 bg-footer-${theme} text-center`}
    >
      <small>
        &copy; {new Date().getFullYear()}, fluxibility - All rights reserved
      </small>
    </footer>
  )
}

import { Col, Container, Row } from "react-bootstrap"
import {
  Cpu as BackEndSVG,
  Cloud as CloudSVG,
  Laptop as FrontEndSVG,
} from "react-bootstrap-icons"
import { Helmet } from "react-helmet-async"

import { FluxibilityServicesSVG } from "domain/services/FluxibilityServicesSVG"
import { PageMeta } from "infra/component/head/HeadMeta"
import { PageHeadSection } from "infra/component/page/PageHeadSection"
import SDProvider from "infra/media/SDProvider.json"
import { ContactButton } from "infra/component/contact/ContactButton"

export const Services = () => {
  const serviceType = "Software Development as well as IT consulting"

  const headerImage = (
    <FluxibilityServicesSVG
      width="600"
      height="533"
      className="img-fluid mb-3 mb-md-0"
    />
  )

  const sectionImageBackend = (
    <BackEndSVG
      color="white"
      width="32"
      height="32"
      className="d-inline-block align-top"
    />
  )
  const sectionImageFrontend = (
    <FrontEndSVG
      color="white"
      width="32"
      height="32"
      className="d-inline-block align-top"
    />
  )
  const sectionImageDevOps = (
    <CloudSVG
      color="white"
      width="32"
      height="32"
      className="d-inline-block align-top"
    />
  )

  return (
    <>
      <PageMeta
        title="Services"
        description="Services offered for your business"
      />
      <Helmet>
        <script
          key="fluxility-services-structured-data"
          type="application/ld+json"
        >
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Service",
            serviceType: serviceType,
            provider: SDProvider,
          })}
        </script>
      </Helmet>

      <PageHeadSection img={headerImage} heading={serviceType}>
        If you need high quality solutions with focus on reliability,
        scalability, security <ContactButton /> us. This is achieved by adhering to and
        staying up-to-date with the latest technologies and engineering
        standards.
      </PageHeadSection>

      <Container>
        <Row as="section" className="mb-5">
          <PageSection
            img={sectionImageBackend}
            imgClassNames="bg-green"
            heading="Backend Services"
          >
            <ServiceParagraph title="Java / Spring">
              Monolithic applications for complex projects with strict security
              requirements
            </ServiceParagraph>
            <ServiceParagraph title="Node.js">
              Micro services for handling simple workflows using cloud
              functions.
            </ServiceParagraph>
            <ServiceParagraph title="MySQL">
              Business data storage for managing relational data.
            </ServiceParagraph>
            <ServiceParagraph title="MongoDB / Firebase">
              Business document storage used for big data and real-time web
              apps.
            </ServiceParagraph>
          </PageSection>

          <PageSection
            img={sectionImageFrontend}
            imgClassNames="bg-purple"
            heading="Frontend Applications"
          >
            <ServiceParagraph title="React">
              Single page applications versatile for every use case.
            </ServiceParagraph>
            <ServiceParagraph title="Bootstrap / Tailwind">
              Professional layout and styling with a mobile first approach.
            </ServiceParagraph>
          </PageSection>

          <PageSection
            img={sectionImageDevOps}
            imgClassNames="bg-blue"
            heading="DevOps"
          >
            <ServiceParagraph title="Google Cloud Platform">
              Host any backend service and frontend application with the highest
              availability globally.
            </ServiceParagraph>
            <ServiceParagraph title="Kubernetes">
              Managed services without vendor lock in to a specific cloud
              provider.
            </ServiceParagraph>
          </PageSection>
        </Row>
      </Container>
    </>
  )
}

interface PageSectionProps {
  img: React.ReactNode
  imgClassNames?: string
  heading: string
  children?: React.ReactNode
}

export const PageSection = ({
  img,
  imgClassNames,
  heading,
  children,
}: PageSectionProps) => (
  <Col lg="4">
    <div
      className={`masthead-followup-icon d-inline-block mb-2 text-white ${imgClassNames}`}
    >
      {img}
    </div>
    <h2 className="mb-5">{heading}</h2>
    {children}
  </Col>
)

interface ServiceParagraphProps {
  title: string
  children: React.ReactNode
}

const ServiceParagraph = ({ title, children }: ServiceParagraphProps) => (
  <div className="mb-5">
    <p className="lead">
      <span className="fw-bold">{title}</span>
    </p>
    <p>{children}</p>
  </div>
)

import { Button } from "react-bootstrap"
import { Envelope } from "react-bootstrap-icons"

import { MAIL } from "infra/consts"

export const ContactButton = () => (
  <Button className="shadow" variant="light" href={`mailto:${MAIL}`}>
    <Envelope width={30} height={30} />{" "}
    <span className="align-middle">CONTACT</span>
  </Button>
)

import { useContext } from "react"
import { Helmet } from "react-helmet-async"

import { ThemeContext } from "infra/component/theme/ThemeContext"

interface PageMetaProps {
  title: string
  description: string
}

export const PageMeta = ({ title, description }: PageMetaProps) => {
  const { theme } = useContext(ThemeContext)

  return (
    <Helmet>
      <title>fluxibility · {title}</title>
      <meta name="description" content={description} />
      {theme === "light" && (
        <style>{"body { background-color: white; color:black; }"}</style>
      )}
    </Helmet>
  )
}

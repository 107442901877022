import { Suspense, useEffect } from "react"
import { Route, Routes, useLocation } from "react-router-dom"

import { Mission } from "domain/mission/Mission"
import { Products } from "domain/products/Products"
import { Services } from "domain/services/Services"
import "infra/app/App.scss"
import { Footer } from "infra/component/footer/Footer"
import { PageMeta } from "infra/component/head/HeadMeta"
import { Header } from "infra/component/header/Header"
import { Loading } from "infra/component/loading/Loading"

export const App = () => {
  const location = useLocation()

  useEffect(() => {
    const { pathname } = location
    window.gtag("config", "G-GWHC2NQNY2", {
      page_title: pathname,
      page_path: pathname,
    })
  })

  return (
    <>
      <PageMeta title="IT solutions" description="fluxibility · IT solutions" />

      <Header />

      <main className="flex-shrink-0 navbar-spacing-top">
        <Routes>
          <Route
            path="/products"
            element={
              <Suspense fallback={<Loading />}>
                <Products />
              </Suspense>
            }
          />
          <Route
            path="/services"
            element={
              <Suspense fallback={<Loading />}>
                <Services />
              </Suspense>
            }
          />
          <Route
            path="*"
            element={
              <Suspense fallback={<Loading />}>
                <Mission />
              </Suspense>
            }
          />
        </Routes>
      </main>

      <Footer />
    </>
  )
}

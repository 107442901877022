import { PageHeadSection } from "infra/component/page/PageHeadSection"
import { FluxibilityMissionSVG } from "infra/media/FluxibilitySVG"

export const Loading = () => (
  <PageHeadSection
    heading="Loading..."
    img={
      <FluxibilityMissionSVG
        width="600"
        height="533"
        className="img-fluid mb-3 mb-md-0"
      />
    }
  />
)
